import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

import { msalConfig } from 'b2c/b2cConf';
import {
	getReasonCodeCanceled,
	getReasonCodeVoid,
} from 'feature/claims/claimSlice';
import { getClaimsStatuses } from 'feature/form/formSlice';
import { logout } from 'feature/user/userSlice';
import { healthCheck } from 'feature/utils/checkConnectionSlice';
import { cleanupStorage } from 'utils/localStorage';

const findRightAccount = (accounts: AccountInfo[]) =>
	accounts.find(el => el.idTokenClaims?.aud === msalConfig.auth.clientId);

export const useLogin = () => {
	const { instance, accounts, inProgress } = useMsal();
	const account = useAccount();

	if (!account) {
		const rightAccount = findRightAccount(accounts);
		rightAccount && instance.setActiveAccount(rightAccount);
	}

	useEffect(() => {
		if (inProgress === InteractionStatus.None && !findRightAccount(accounts)) {
			const tokenRequest = {
				scopes: [],
			};
			cleanupStorage();
			instance.acquireTokenRedirect(tokenRequest);
		}
	}, [accounts, inProgress, instance]);
};

export const useLogout = () => {
	const { instance, accounts } = useMsal();
	const dispatch = useDispatch<AppDispatch>();

	return useCallback(() => {
		dispatch(logout({ instance, accounts }));
	}, [accounts, dispatch, instance]);
};

export const useCheckAlive = () => {
	const isAuthenticated = useIsAuthenticated();
	const dispatch = useDispatch<AppDispatch>();
	const { alive } = useSelector((state: RootState) => state.healthCheck);

	useEffect(() => {
		if (isAuthenticated && alive === undefined) {
			dispatch(healthCheck(''));
		}
	}, [alive, dispatch, isAuthenticated]);
};

export const useGetLocales = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { alive } = useSelector((state: RootState) => state.healthCheck);

	useEffect(() => {
		if (alive) {
			dispatch(getClaimsStatuses(''));
		}
	}, [dispatch, alive]);

	useEffect(() => {
		if (alive) dispatch(getReasonCodeVoid({}));
	}, [alive, dispatch]);

	useEffect(() => {
		if (alive) dispatch(getReasonCodeCanceled({}));
	}, [alive, dispatch]);
};

import SelectGeneric from 'components/commons/form/SelectGeneric';
import { voidClaim } from 'feature/claims/claimSlice';
import { resetDialogConfirm } from 'feature/hooks/dialogSlice';
import {
	resetInputStore,
	setInputHandleChange,
} from 'feature/input/inputSlice';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from 'store/store';
import { Button, Stack, TextField, Typography } from 'styles';
import palette from 'styles/theme/Palette';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';

const ClaimVoidModalContent = () => {
	const location = useLocation();
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { t: tDbLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);
	const { reasonCodesVoid } = useSelector((store: RootState) => store.claim);
	const { reasonCode, reasonNote } = useSelector(
		(store: RootState) => store.input,
	);
	const [reasonError, setReasonError] = useState<boolean>(false);

	const splittedLocation = location.pathname.split('/');

	useEffect(() => {
		return () => {
			dispatch(resetInputStore());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeSelect = (item: string) => {
		dispatch(setInputHandleChange({ name: 'reasonCode', value: item }));
		setReasonError(false);
	};

	const handleConfirm = () => {
		if (
			!_.isEmpty(reasonCode) &&
			(_.isNull(reasonNote) ||
				(_.isString(reasonNote) && reasonNote?.length <= 500))
		) {
			dispatch(voidClaim(splittedLocation[splittedLocation.length - 1]));
		} else if (_.isEmpty(reasonCode)) {
			setReasonError(true);
		}
	};

	return (
		<Stack gap={2}>
			<Typography variant="bodyLink">
				{tClientLabels('dialog.reason.subtitle')}
			</Typography>
			<SelectGeneric
				sx={{ mb: '22px' }}
				action={(payload: string) => handleChangeSelect(payload)}
				items={reasonCodesVoid.map(el => ({
					text: tDbLabels(`${el.label}`),
					value: el.code,
				}))}
				name={'reason'}
				initialValue={reasonCode || ''}
				error={reasonError}
			/>
			<Stack sx={{ position: 'relative' }}>
				<TextField
					label={'Note'}
					name={'note'}
					multiline
					sx={{ height: 'auto', mb: '22px' }}
					rows={8}
					onChange={e =>
						dispatch(
							setInputHandleChange({ name: 'reasonNote', value: e.target.value }),
						)
					}
					value={reasonNote || ''}
					error={!!reasonNote && reasonNote.length > 500}
				/>
				{reasonNote && reasonNote.length > 500 && (
					<Typography
						variant="bodyLink"
						sx={{ position: 'absolute', bottom: 0 }}
						color={palette.error.main}
					>
						Text cannot be longer than 500 characters
					</Typography>
				)}
			</Stack>
			<Stack>
				<Typography variant="bodyLink" mb={0.5} textAlign={'right'}>
					{tClientLabels('dialog.reason.confirm', {
						code: splittedLocation[splittedLocation.length - 1],
					})}
				</Typography>
			</Stack>
			<Stack flexDirection={'row'} gap={1} justifyContent={'flex-end'}>
				<Button
					variant="outlined"
					sx={{
						width: 'fit-content',
					}}
					onClick={() => dispatch(resetDialogConfirm())}
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					sx={{
						width: 'fit-content',
						backgroundColor: palette.black.main,
					}}
					onClick={handleConfirm}
				>
					Confirm
				</Button>
			</Stack>
		</Stack>
	);
};

export default ClaimVoidModalContent;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	calculateEstimatesThunk,
	getClaimDetailsThunk,
	getReasonCodeCanceledThunk,
	getReasonCodeVoidThunk,
	rekeyClaimThunk,
	updateClaimStatusThunk,
	voidClaimThunk,
} from './ClaimThunk';

import { showSnackBar } from 'styles/snackbar/showSnackBar';
import palette from 'styles/theme/Palette';
import {
	IClaim,
	IClaimAccumulator,
	IClaimComponents,
	IClaimState,
} from 'types/Claim';

const initialState: IClaimState = {
	claimData: {
		claimInNetwork: false,
		claimId: undefined,
		externalClaimId: '',
		beneficiaryMemberId: '',
		memberId: '',
		beneficiarySubscriptionId: 0,
		memberSubscriptionId: 0,
		providerId: '',
		claimDate: '',
		step: 0,
		status: {
			id: 1,
			name: '',
			label: '',
			groupCode: '',
			groupLabel: '',
		},
		// page 1
		exam: 0,
		materials: 0,
		lensType: undefined,
		rxChangedWithoutOldPrescription: null,
		//page 2
		newPrescriptionContent: undefined,
		oldPrescriptionContent: undefined,
		prescriptionConsent: false,
		//page 3
		amount: {
			//basically everything is passed by calculate. only serviceid and retail amount is not
			components: [],
			total: {
				retailAmount: '',
				total: undefined,
				contractualAdjustmentAmount: undefined,
				memberPayAmount: undefined,
				planPayAmount: undefined,
				serviceName: undefined,
				serviceId: null,
				discountedAmount: undefined,
				assessment: undefined,
				allowablePlanPay: undefined,
				coPay: undefined,
				coInsurance: undefined,
				disallowedAmount: undefined,
				decliningBalance: undefined,
				claimCostReduction: undefined,
				inNetworkSave: undefined,
			},
		},
		rxChangeAttestation: undefined,
		rxAttestation: undefined,
		attachments: {
			prescription: undefined,
			others: undefined,
			receipt: undefined,
		},
		uploadConsent: false,
		reasonCode: '',
		internalNote: '',
	},
	rekeyClaim: undefined,
	errorCode: 0,
	errorMessage: '',
	triggerRefresh: true,
	calculated: false,
	redirect: false,
	doctorTypes: [],
	reasonCodesVoid: [],
	reasonCodesCanceled: [],
	servicesMap: [],
};

export const getClaimDetails = createAsyncThunk(
	'claim/getClaimDetails',
	getClaimDetailsThunk,
);

export const calculateEstimates = createAsyncThunk(
	'claim/calculateEstimates',
	calculateEstimatesThunk,
);

export const updateClaimStatus = createAsyncThunk(
	'claim/updateClaimStatus',
	updateClaimStatusThunk,
);

export const rekeyClaimAction = createAsyncThunk(
	'claim/rekeyClaim',
	rekeyClaimThunk,
);

export const getReasonCodeVoid = createAsyncThunk(
	'claim/getReasonCodeVoid',
	getReasonCodeVoidThunk,
);

export const getReasonCodeCanceled = createAsyncThunk(
	'claim/getReasonCodeCanceled',
	getReasonCodeCanceledThunk,
);

export const voidClaim = createAsyncThunk('claim/voidClaim', voidClaimThunk);

const claimSlice = createSlice({
	name: 'claim',
	initialState,
	reducers: {
		resetClaim: state => ({
			...initialState,
			reasonCodesCanceled: state.reasonCodesCanceled,
			reasonCodesVoid: state.reasonCodesVoid,
		}),
		resetRekey: state => ({ ...state, calculated: true, rekeyClaim: undefined }),
		setCalculated: (state, action) => ({ ...state, calculated: action.payload }),
	},
	extraReducers: builder => {
		builder
			.addCase(getClaimDetails.fulfilled, (state, { payload }) => {
				const { servicesMap, ...rest } = payload;

				state.claimData = rest;
				state.servicesMap = servicesMap;
			})
			.addCase(getClaimDetails.rejected, () => {
				setTimeout(() => (window.location.href = '/'), 1500);
			})
			.addCase(
				calculateEstimates.fulfilled,
				(
					state,
					{
						payload,
					}: {
						payload: {
							//basically everything is passed by calculate. only serviceid and retail amount is not
							components: IClaimComponents[];
							total: IClaimComponents;
							accumulator?: IClaimAccumulator;
						};
					},
				) => {
					return {
						...state,
						calculated: true,
						rekeyClaim: {
							...state.claimData,
							amount: { ...payload, components: sortServices(payload.components) },
						},
					};
				},
			)
			.addCase(rekeyClaimAction.fulfilled, state => {
				showSnackBar(`Claim updated with success`, {
					style: { background: palette.primary.main },
				});
				setTimeout(() => window.location.reload(), 1500);
				return {
					...state,
					rekeyClaim: undefined,
				};
			})
			.addCase(updateClaimStatus.fulfilled, (state, { payload }) => {
				showSnackBar(`Claim updated with success`, { variant: 'success' });
				setTimeout(() => window.location.reload(), 1500);
			})
			.addCase(getReasonCodeVoid.fulfilled, (state, { payload }) => {
				state.reasonCodesVoid = payload;
			})
			.addCase(getReasonCodeCanceled.fulfilled, (state, { payload }) => {
				state.reasonCodesCanceled = payload;
			})
			.addCase(voidClaim.fulfilled, () => {
				showSnackBar(`Claim voided with success`, {
					style: { background: palette.primary.main },
				});
				setTimeout(() => window.location.reload(), 1500);
			});
	},
});

const sortServices = (
	components: IClaim['amount']['components'] | undefined,
): IClaim['amount']['components'] => {
	const sortOreder = [3, 2, 1, 5];
	return components
		? components.sort(
				(a, b) =>
					sortOreder.indexOf(a.serviceId!) - sortOreder.indexOf(b.serviceId!),
		  )
		: [];
};

export const { resetClaim, resetRekey, setCalculated } = claimSlice.actions;
export default claimSlice.reducer;

import { resetDialogConfirm } from 'feature/hooks/dialogSlice';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { IClaim, REASON_CODE_TYPOLOGY } from 'types/Claim';
import { ThunkApiType } from 'types/utils';

export const getClaimDetailsThunk = async (
	claimid: string,
	thunkAPI: ThunkApiType,
) => {
	let getClaimDetailsURL = `/claim/${claimid}/complete`;
	try {
		const response = await authFetch.get(getClaimDetailsURL);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const calculateEstimatesThunk = async (
	formData: IClaim,
	thunkAPI: ThunkApiType,
) => {
	try {
		const response = await authFetch.post('/claim/calculateCost', formData);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const updateClaimStatusThunk = async (
	{ claimIds, statusId }: { claimIds: number[]; statusId: number },
	thunkAPI: ThunkApiType,
) => {
	let updateClaimStatus = `/claim`;
	try {
		const response = await authFetch.put(updateClaimStatus, {
			claimIds,
			statusId,
		});
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const rekeyClaimThunk = async (
	{
		id,
		formData,
	}: {
		id: string;
		formData: IClaim;
	},

	thunkAPI: ThunkApiType,
) => {
	const rekeyClaimURL = `/claim/${id}/rekey`;
	try {
		const response = await authFetch.post(rekeyClaimURL, formData);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getReasonCodeVoidThunk = async (
	_: object,
	thunkAPI: ThunkApiType,
) => {
	try {
		const response = await authFetch.get(
			`/reasonCode?typology=${REASON_CODE_TYPOLOGY.VOID}`,
		);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getReasonCodeCanceledThunk = async (
	_: object,
	thunkAPI: ThunkApiType,
) => {
	try {
		const response = await authFetch.get(
			`/reasonCode?typology=${REASON_CODE_TYPOLOGY.CANCEL}`,
		);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const voidClaimThunk = async (
	claimId: string,
	thunkAPI: ThunkApiType,
) => {
	let voidClaimURL = `/claim/${claimId}/void`;

	const { reasonCode, reasonNote } = thunkAPI.getState().input;

	const body = {
		reasonCode,
		note: reasonNote,
	};

	try {
		const response = await authFetch.post(voidClaimURL, body);
		thunkAPI.dispatch(resetDialogConfirm());
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
